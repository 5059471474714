import AssetTimeline from 'common/components/AssetTimeline';
import { getChanges, getRevisions } from 'common/components/AssetTimeline/util';
import getHHSRevisionsIncludingLegacyArchives from 'common/components/AssetTimeline/hhsLegacyShim';
import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import { View } from 'common/types/view';
import { isDefaultView, isTabular } from 'common/views/view_types';
import get from 'lodash/get';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const t = (k: string, scope = 'dataset_landing_page.archival') => I18n.t(k, { scope });

interface Props {
  view: View;
}

interface State {}

const isEligible = (view: View) => {
  return FeatureFlags.value('enable_asset_archival') && isDefaultView(view) && isTabular(view);
};

const fetchRevisionsFunc = (view: View) =>
  FeatureFlags.valueOrDefault('enable_hhs_legacy_archives_in_asset_timeline', false)
    ? getHHSRevisionsIncludingLegacyArchives(view)
    : getChanges(view);

export class DatasetArchives extends Component<Props, State> {
  render() {
    const { view } = this.props;
    if (!isEligible(view)) {
      return null;
    }

    return (
      <section className="landing-page-section archived-versions">
        <h2 className="dataset-landing-page-header">{t('title')}</h2>
        <AssetTimeline view={view} getChanges={fetchRevisionsFunc(view)} />
      </section>
    );
  }
}

export const mapStateToProps = (state: any) => {
  return { view: get(state, 'view.coreView') };
};

export default connect(mapStateToProps)(DatasetArchives);
