import { checkStatus, defaultHeaders } from 'common/http';
import { RELATED_VIEWS_FETCH_LIMIT } from '../lib/constants';
import {
  TOGGLE_RELATED_VIEWS,
  REQUEST_RELATED_VIEWS,
  RECEIVE_RELATED_VIEWS,
  HANDLE_RELATED_VIEWS_ERROR,
  DISMISS_RELATED_VIEWS_ERROR
} from '../actionTypes';
import { get } from 'lodash';
import { GetState, Dispatcher } from '../lib/types';

export enum FilterType {
  AllViews = 'All Views',
  FilteredViews = 'Filtered Views',
  CommunityCreated = 'Community Created',
  Official = 'Official',
  Maps = 'Maps',
  Stories = 'Stories',
  Calendars = 'Calendars',
  Charts = 'Charts',
  Measures = 'Measures'
}

export enum SortType {
  Popularity = 'Popularity',
  MostRecent = 'Most Recent',
  OldestNewest = 'Oldest to Newest',
  AtoZ = 'A-Z',
  ZtoA = 'Z-A',
  RecentlyUpdated = 'Recently Updated'
}

export function toggleRelatedViews() {
  return {
    type: TOGGLE_RELATED_VIEWS
  };
}

export function requestRelatedViews() {
  return {
    type: REQUEST_RELATED_VIEWS
  };
}

// TODO: type for relatedviews response
export function fetchedRelatedViews(relatedViews: any, resetViews: boolean) {
  return {
    type: RECEIVE_RELATED_VIEWS,
    relatedViews: relatedViews.results,
    relatedViewCount: relatedViews.total_count,
    resetViews
  };
}

export function handleRelatedViewsError() {
  return {
    type: HANDLE_RELATED_VIEWS_ERROR
  };
}

export function dismissRelatedViewsError() {
  return {
    type: DISMISS_RELATED_VIEWS_ERROR
  };
}



export function fetchRelatedViews(searchText: string, filterBy: FilterType, sortType: SortType, resetViews: boolean) {
  return (dispatch: Dispatcher, getState: GetState) => {
    const state = getState();

    if (get(state, 'relatedViews.isLoading', false)) {
      return;
    }

    const uid = state.view.id;
    const offset = resetViews ? 0 : get(state, 'relatedViews.viewList.length', 0);
    const limit = RELATED_VIEWS_FETCH_LIMIT;
    const sortBy = sortTypeToCeteraSortBy(sortType);
    const filter = filterTypeToCeteraParam(filterBy);
    const fetchUrl = `/dataset_landing_page/${uid}/related_views?limit=${limit}&offset=${offset}&q=${searchText}&sort_by=${sortBy}&include_count=true&${filter}`;

    if (!resetViews) {
      // causes the spinner at the bottom of the list to show
      dispatch(requestRelatedViews());
    }

    fetch(fetchUrl, {
      credentials: 'same-origin',
      headers: defaultHeaders
    }).
      then(checkStatus).
      then((response) => response.json()).
      then((relatedViews) => {
        dispatch(fetchedRelatedViews(relatedViews, resetViews));
      }).catch(() => dispatch(handleRelatedViewsError()));
  };
}

function sortTypeToCeteraSortBy(sortType: SortType) {
  switch (sortType) {
    case SortType.Popularity:
      return 'most_accessed';
    case SortType.MostRecent:
      return 'newest';
    case SortType.OldestNewest:
      return 'oldest';
    case SortType.AtoZ:
      return 'name';
    case SortType.ZtoA:
      return 'alpha_reversed';
    case SortType.RecentlyUpdated:
      return 'last_modified';
  }
}

function filterTypeToCeteraParam(filterBy: FilterType) {
  switch (filterBy) {
    case FilterType.AllViews:
      return '';
    case FilterType.FilteredViews:
      return 'only=filter';
    case FilterType.CommunityCreated:
      return 'provenance=community';
    case FilterType.Official:
      return 'provenance=official';
    case FilterType.Maps:
      return 'only=map';
    case FilterType.Stories:
      return 'only=story';
    case FilterType.Calendars:
      return 'only=calendar';
    case FilterType.Charts:
      return 'only=chart';
    case FilterType.Measures:
      return 'only=measure';
  }
}
