/**
 * Show the access manager
 * @param {event} e Click event from whatever triggered this function
 * @param {function} onAccessManagerShown Function to call when the access manager is shown (optional)
 */
export const showAccessManager = (e: any, onAccessManagerShown: { (): void; (): void; }, mode = 'change_audience') => {
  if (onAccessManagerShown) {
    onAccessManagerShown();
  }

  const refreshOnSave = true;
  const windowSocrata: any = window.socrata;

  windowSocrata.showAccessManager(mode, refreshOnSave);
  e.preventDefault();
};
