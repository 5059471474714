import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { handleKeyPress } from 'common/dom_helpers/keyPressHelpers';
import I18n from 'common/i18n';
export class Notice extends Component {

  render() {
    const { children, onClickDismiss, additionalClasses } = this.props;
    const noticeClasses = ['alert', 'info', 'alert-full-width-top', 'notice'].concat(additionalClasses || []);
    return (
      <div className={noticeClasses.join(' ')}>
        <div className="alert-container">
          {children}
          <span
            className="icon-close-2 alert-dismiss"
            aria-label={I18n.close}
            role="button"
            tabIndex="0"
            onClick={onClickDismiss}
            onKeyDown={handleKeyPress(onClickDismiss)}>
          </span>
        </div>
      </div>
    );
  }
}

Notice.propTypes = {
  children: PropTypes.node,
  additionalClasses: PropTypes.arrayOf(PropTypes.string),
  onClickDismiss: PropTypes.func.isRequired
};

export default Notice;
