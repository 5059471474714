import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { handleAlertNoticeClose } from '../actions/view';
import Notice from './Notice';

export class AlertNotice extends Component {
  onClickDismiss = () => {
    this.props.onAlertSavedNoticeClose();
  };

  render() {
    const { showAlertSavedNotice } = this.props.view;

    if (!showAlertSavedNotice) {
      return null;
    }

    return (
      <Notice onClickDismiss={this.onClickDismiss}>
        <span>{I18n.alert_saved_message}</span>
      </Notice>
    );
  }
}

AlertNotice.propTypes = {
  view: PropTypes.object.isRequired,
  onAlertSavedNoticeClose: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return _.pick(state, 'view');
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onAlertSavedNoticeClose: handleAlertNoticeClose
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertNotice);
