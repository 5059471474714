import { replace as _replace } from 'lodash';
import { connect } from 'react-redux';
import BlobDownload from 'common/components/BlobbyDatasets/BlobDownload';

export function mapStateToProps(state) {
  const view = state.view;
  const downloadLink = `/download/${view.id}/${encodeURIComponent(_replace(view.blobMimeType, /;.*/, ''))}`;
  const scanResultUrl = `/api/views/${view.id}/files/${view.blobId}?method=judgement`;

  return {
    showDownloadSection: view.isBlobby,
    blobFilename: view.blobFilename,
    downloadLink: downloadLink,
    scanResultUrl
  };
}

export default connect(mapStateToProps)(BlobDownload);
