import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import I18n from 'common/i18n';
import { View } from 'common/types/view';
import airbrake from 'common/airbrake';
import { ViewColumn } from 'common/types/viewColumn';

const t = (k: string, scope = 'dataset_landing_page.plotly_modal') => I18n.t(k, { scope });

interface PlotlyData {
  cols: {
    [column: string]: {
      data: any[];
    };
  };
}

interface PlotlyModalState {
  hasError: boolean;
  opening: boolean;
}

interface PlotlyModalProps {
  getPlotlyData: (view: View) => Promise<PlotlyData>;
  openInPlotly: (data: PlotlyData) => void;
  view: View;
}

const getPlotlyData = async (view: View): Promise<PlotlyData> => {
  const { data, meta } = await fetchJsonWithDefaultHeaders(view.plotlyUrl ?? '');
  const cols: Record<string, { data: any[] }> = {};
  data.forEach((row: any[]) => {
    row.forEach((val: any, i: number) => {
      const col: ViewColumn = meta.view.columns[i];
      if (!col.fieldName.startsWith(':') && col.name) {
        if (cols[col.name]) {
          cols[col.name].data.push(val);
        } else {
          cols[col.name] = { data: [val] };
        }
      }
    });
  });
  return { cols };
};

const openInPlotly = (data: PlotlyData) => {
  const form = document.createElement('form');
  const input = document.createElement('input');

  form.action = 'https://chart-studio.plotly.com/datagrid';
  form.method = 'post';
  form.setAttribute('style', 'display:none;');
  input.type = 'text';
  input.name = 'data';
  input.value = JSON.stringify(data);

  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export class PlotlyModal extends Component<PlotlyModalProps, PlotlyModalState> {
  state = {
    hasError: false,
    opening: false
  };

  static defaultProps = {
    getPlotlyData,
    openInPlotly
  };

  onClickPlotly = async () => {
    this.setState({
      hasError: false,
      opening: true
    });

    try {
      const data = await this.props.getPlotlyData(this.props.view);
      this.props.openInPlotly(data);
    } catch (err) {
      airbrake.notify({
        error: `Error opening dataset in Plotly: ${err}`,
        context: { component: 'PlotlyModal' }
      });
      this.setState({ hasError: true });
    }

    this.setState({ opening: false });
  };

  render() {
    const { hasError, opening } = this.state;

    return (
      <div
        role="dialog"
        aria-labelledby="plotly-modal-title"
        id="plotly-modal"
        className="modal modal-overlay modal-hidden"
        data-modal-dismiss
      >
        <div className="modal-container">
          <header className="modal-header">
            <h2 id="plotly-modal-title" className="h2 modal-header-title">
              {t('title')}
            </h2>

            <button
              aria-label={t('cancel', 'dataset_landing_page')}
              className="btn btn-transparent modal-header-dismiss"
              data-modal-dismiss
            >
              <span className="icon-close-2" />
            </button>
          </header>

          <section className="modal-content">
            {t('modal_content')}
            <div className="alert info">
              <span className="icon-info" />
              <div>{t('alert')}</div>
            </div>
            {t('learn_more_link')} <br />
            <a href="https://support.socrata.com/hc/en-us/articles/115010730868">
              https://support.socrata.com/hc/en-us/articles/115010730868
            </a>
            {hasError && (
              <div className="alert error">
                <span className="icon-info" />
                <div>{t('error_message')}</div>
              </div>
            )}
          </section>

          <footer className="modal-actions">
            <button className="btn btn-default btn-sm" data-modal-dismiss>
              {t('close', 'dataset_landing_page')}
            </button>

            <button className="btn btn-primary btn-sm" disabled={opening} onClick={this.onClickPlotly}>
              {t('open', 'dataset_landing_page')}
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return _.pick(state, 'view');
}

export default connect(mapStateToProps)(PlotlyModal);
