import { connect } from 'react-redux';
import { get as _get } from 'lodash';
import BlobPreview from 'common/components/BlobbyDatasets/BlobPreview';
import { assetIsPublic } from 'common/views/helpers';

const MAX_PREVIEWABLE_BLOB_SIZE = 26214400;

export function mapStateToProps(state) {
  const { view } = state;
  const previewProps = {
    isPreviewable: false,
    blobNotPublic: false,
    blobTooLarge: false,
    previewUrl: `/api/views/${view.id}/files/${view.blobId}`,
    previewType: view.blobType,
    blobName: view.name
  };

  if (!view.isBlobby) {
    return previewProps;
  }

  if (view.blobType === 'google_viewer') {
    if (!assetIsPublic(view.coreView)) {
      return {
        ...previewProps,
        blobNotPublic: true
      };
    }

    const blobFileSize = _get(view, ['coreView', 'blobFileSize'], 0);

    if (view.blobType === 'google_viewer' && blobFileSize > MAX_PREVIEWABLE_BLOB_SIZE) {
      return {
        ...previewProps,
        blobTooLarge: true
      };
    }
  }

  return {
    ...previewProps,
    isPreviewable: true
  };
}

export default connect(mapStateToProps)(BlobPreview);
