import get from 'lodash/get';
import pick from 'lodash/pick';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DismissibleNotice from './DismissibleNotice';
import { SocrataIcon } from 'common/components/SocrataIcon';
import { hasOwnerLikeRights } from 'common/views/has_rights';

export class ScheduleFailureNotice extends Component {

  showScheduleFailureNotice() {
    // set by dataset_landing_page.rb initialState
    const viewSchedule = get(
      window,
      'initialState.view.dsmapiScheduledUpdates.resource',
      {});
    const scheduleFailed = viewSchedule.consecutive_failures > 0;
    return hasOwnerLikeRights(this.props.view.coreView) && scheduleFailed;
  }

  render() {
    const { view } = this.props;

    if (!this.showScheduleFailureNotice()) {
      return null;
    }

    return (
      <DismissibleNotice
        noticeTypeName={'dismissedScheduleFailureNotices'}
        noticeIdentifier={view.id}
        additionalClasses={['error', 'schedule-failure-notice']}>
        <SocrataIcon name="warning" />
        {' '}
        {I18n.schedule_failure}
      </DismissibleNotice>
    );
  }
}

ScheduleFailureNotice.propTypes = {
  view: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return pick(state, 'view');
}

export default connect(mapStateToProps)(ScheduleFailureNotice);
