import { ForgeButton, ForgeScaffold, ForgeTab, ForgeTabBar, ForgeView, ForgeViewSwitcher } from '@tylertech/forge-react';
import { ITabBarActivateEventData } from '@tylertech/forge';
import React, { CSSProperties, FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import I18n from 'common/i18n';
import ActionButton from './ActionButton';
import { DatasetLandingPageEnhancedView } from 'common/types/view';
import { TOGGLE_OPTIONS } from 'common/components/ExportModal';
import { getSiteChromeHeight, iaSiteChromeHeaderFixed } from 'common/util/siteMeasurements';
import { browserHistory } from 'react-router';

const i18nScope = { scope: 'dataset_landing_page.tabs' };

type TabViewerProps = PropsWithChildren<unknown> & {
  view: DatasetLandingPageEnhancedView,
  openExportDialog: (exportModalDefaultToggle: TOGGLE_OPTIONS) => void,
  currentTab: DslpTab
};

export enum DslpTab {
  About = 'about_data',
  Data = 'data_preview',
  RelatedContent = 'related_content'
}

const tabIndexes = {
  [DslpTab.About]: 0,
  [DslpTab.Data]: 1,
  [DslpTab.RelatedContent]: 2
};

const indexedTabs = {
  0: [DslpTab.About],
  1: [DslpTab.Data],
  2: [DslpTab.RelatedContent]
};

const TabViewSwitcher: FunctionComponent<TabViewerProps> = ({
  children,
  view,
  openExportDialog,
  currentTab
}) => {
  // These refs are used to set focus manually
  const aboutTabRef = useRef<any>();
  const dataTabRef = useRef<any>();
  const relatedContentTabRef = useRef<any>();
  const [index, setIndex] = useState<number>(tabIndexes[currentTab]);
  const [renderTable, setRenderTable] = useState<boolean>(false);
  useEffect(() => {
    setIndex(tabIndexes[currentTab]);
    // We wait to render the data table until its clicked on in the tab. This is to avoid a bug with the ag-grid and is more performant
    if (tabIndexes[currentTab] === tabIndexes[DslpTab.Data] && !renderTable) setRenderTable(true);
    setTabFocus(currentTab);
  }, [currentTab]);

  // we are setting this manually otherwise it can get screwed up with things like the back button
  const setTabFocus = (tab: DslpTab) => {
    switch (tab) {
      case DslpTab.About: {
        if (aboutTabRef.current) aboutTabRef.current.focus();
        break;
      }
      case DslpTab.Data: {
        if (aboutTabRef.current) dataTabRef.current.focus();
        break;
      }
      case DslpTab.RelatedContent: {
        if (aboutTabRef.current) relatedContentTabRef.current.focus();
        break;
      }
    }
  };

  const onTabClick = (tabIndex: number) => {
    setIndex(tabIndex);
    const pathRegexp = /(?<path>\/(?:dataset|.+)\/(?:.+)\/(?:\w{4}-\w{4}))/;
    const path = window.location.pathname.match(pathRegexp)?.groups?.path || '';

    if (path) {
      browserHistory.push(`${path}/${indexedTabs[tabIndex]}`);
    }
  };

  const viewSwitcherProps = {
    animationType: 'none',
  };
  const viewStyles: CSSProperties = {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  // if the view is from href or blob we want to hide some of the tabs
  const viewHrefOrBlob = !!view.isBlobby || !!view.isHref;

  const getHeaderOffset = () => {
    if (iaSiteChromeHeaderFixed()) return getSiteChromeHeight();
     // unrolled does not use a sticky header, so we don't care how big it is it scrolls off screen
    return '0';
  };

  return (
    <div id="ViewSwitcher" className={'view-switcher-wrapper'}>
      <div id='view-switcher-nav-bar' className='view-switcher-header'
        style={{top: getHeaderOffset()}}
      >
        <ForgeTabBar className='tab-bar-container' underline layoutMode={'clustered'} activeTab={index} on-forge-tab-bar-activate={(event: CustomEvent<ITabBarActivateEventData>) => onTabClick(event.detail.index)}>
          <ForgeTab className={'primer-tabs first-tab'} data-testid='about-tab' ref={aboutTabRef}>{I18n.t('about', i18nScope)}</ForgeTab>
          { !viewHrefOrBlob && <ForgeTab className={'primer-tabs'} data-testid='data-tab' ref={dataTabRef}>{I18n.t('data', i18nScope)}</ForgeTab> }
          { !viewHrefOrBlob && <ForgeTab className={'primer-tabs'} data-testid='related-content-tab' ref={relatedContentTabRef}>{I18n.t('related_content', i18nScope)}</ForgeTab> }
        </ForgeTabBar>
        <div>
          <div className='action-button-wrapper'>
          <ActionButton openExportModalToApi={() => {
            openExportDialog(TOGGLE_OPTIONS.API_ENDPOINT);
          }}/>
          {!viewHrefOrBlob &&
            <ForgeButton type='outlined' className='export-button' data-testid='export-data-button'>
             <button onClick={() => openExportDialog(TOGGLE_OPTIONS.DOWNLOAD_FILE)}>
               {I18n.t('export', i18nScope)}
             </button>
           </ForgeButton>
          }
          </div>
        </div>
      </div>

      <ForgeScaffold className="tab-view-switcher-scaffold">
        <ForgeViewSwitcher slot={'body'} {...viewSwitcherProps} index={index}>
          <ForgeView role="tabpanel">
            <div style={viewStyles} className="forge-typography--body1">{children![tabIndexes[DslpTab.About]]}</div>
          </ForgeView>
          <ForgeView role="tabpanel">
            {/* To avoid a bug with ag-grid we only render the table when its clicked on ...sort of like lazy loading */}
            {renderTable && <div style={viewStyles} className="forge-typography--body1">{children![tabIndexes[DslpTab.Data]]}</div>}
          </ForgeView>
          <ForgeView role="tabpanel">
            <div style={viewStyles} className="forge-typography--body1">{children![tabIndexes[DslpTab.RelatedContent]]}</div>
          </ForgeView>
        </ForgeViewSwitcher>
      </ForgeScaffold>
    </div>
  );
};
export default TabViewSwitcher;
