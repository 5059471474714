import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import I18n from 'common/i18n';
import ViewCard from 'common/components/ViewCard';
import { getViewCardPropsForView } from 'common/viewCardHelpers';

const t = (translationKey) => I18n.t(translationKey, { scope: 'dataset_landing_page' });

export class ViewSelector extends Component {
  constructor(props) {
    super(props);
  }

  renderChooseButton(viewUid) {
    const { onClickChoose, isSaving, isSaved, isSavingViewUid, hasSaveError } = this.props;
    const isSavingSelf = isSavingViewUid === viewUid;
    const renderSavingButton = isSaving && isSavingSelf;
    const renderSavedButton = isSaved && isSavingSelf;
    const renderErrorButton = hasSaveError && isSavingSelf;

    const chooseButtonClassName = classNames({
      btn: true,
      'btn-primary': true,
      'view-select': true,
      'btn-busy': renderSavingButton,
      'btn-success': renderSavedButton,
      'btn-error': renderErrorButton
    });

    let chooseButtonContents;

    if (renderSavingButton) {
      chooseButtonContents = <div className="spinner-default spinner-btn-primary" />;
    } else if (renderSavedButton) {
      chooseButtonContents = `${t('saved')}!`;
    } else if (renderErrorButton) {
      chooseButtonContents = t('error');
    } else {
      chooseButtonContents = t('choose');
    }

    return (
      <button
        className={chooseButtonClassName}
        disabled={renderSavingButton}
        onClick={_.partial(onClickChoose, viewUid)}
        data-testid="view-selector-choose-button"
      >
        {chooseButtonContents}
      </button>
    );
  }

  render() {
    const { viewList, renderNoViews } = this.props;
    let viewContent;

    if (viewList.length === 0) {
      viewContent = renderNoViews();
    } else {
      viewContent = _.map(viewList, (relatedView, i) => (
        <ViewCard {...getViewCardPropsForView(relatedView)} key={i}>
          {this.renderChooseButton(relatedView.id)}
        </ViewCard>
      ));
    }

    return <div className="internal-view-list">{viewContent}</div>;
  }
}

ViewSelector.propTypes = {
  hasSaveError: PropTypes.bool,
  isSaved: PropTypes.bool,
  isSaving: PropTypes.bool,
  isSavingViewUid: PropTypes.string,
  onClickChoose: PropTypes.func,
  renderNoViews: PropTypes.func,
  viewList: PropTypes.array.isRequired
};

export default ViewSelector;
