import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import I18n from 'common/i18n';

const t = (translationKey) => I18n.t(translationKey, { scope: 'dataset_landing_page' });

class FeaturedContentModalHeader extends PureComponent {
  render() {
    const { onClickClose } = this.props;

    return (
      <header className="modal-header">
        <h1>{t('featured_content_modal.header')}</h1>

        <button
          className="btn btn-transparent modal-header-dismiss"
          data-modal-dismiss
          aria-label={t('close')}
          onClick={onClickClose}
        >
          <span className="icon-close-2" />
        </button>
      </header>
    );
  }
}

FeaturedContentModalHeader.propTypes = {
  onClickClose: PropTypes.func
};

export default FeaturedContentModalHeader;
