import _ from 'lodash';
import { checkStatus, defaultHeaders, redirectTo } from 'common/http';
import { getAnalysisId } from 'common/program_analytics/helpers';

import {
  CHECK_SUBSCRIPTION_ON_LOAD,
  CLEAR_VIEW_PUBLISH_ERROR,
  HANDLE_ALERT_NOTICE_CLOSE,
  HANDLE_ALERT_SAVE_SUCCESS,
  HANDLE_CHECK_SUBSCRIPTION_ON_LOAD_ERROR,
  HANDLE_FETCH_ROW_COUNT_ERROR,
  HANDLE_FETCH_ROW_COUNT_SUCCESS,
  HANDLE_VIEW_PUBLISH_ERROR,
  HANDLE_VIEW_PUBLISH_SUCCESS,
  ON_SUBSCRIPTION_CHANGE,
  REQUESTED_VIEW_PUBLISH,
  SET_VIEW_ANALYSIS_ID
} from '../actionTypes';
import { isBlob, isHref } from '../../../../../common/views/view_types';

export function requestedViewPublish() {
  return { type: REQUESTED_VIEW_PUBLISH };
}

export function handleViewPublishSuccess() {
  return { type: HANDLE_VIEW_PUBLISH_SUCCESS };
}

export function handleViewPublishError() {
  return { type: HANDLE_VIEW_PUBLISH_ERROR };
}

export function clearViewPublishError() {
  return { type: CLEAR_VIEW_PUBLISH_ERROR };
}

export function handleFetchRowCountSuccess(rowCount) {
  return {
    type: HANDLE_FETCH_ROW_COUNT_SUCCESS,
    rowCount
  };
}

export function handleFetchRowCountError() {
  return { type: HANDLE_FETCH_ROW_COUNT_ERROR };
}

export function onSubscriptionChange(subscriptionId) {
  return {
    type: ON_SUBSCRIPTION_CHANGE,
    subscriptionId,
    subscribed: _.isNumber(subscriptionId) && !_.isNaN(subscriptionId)
  };
}

export function handleAlertSaveSuccess() {
  return { type: HANDLE_ALERT_SAVE_SUCCESS };
}

export function handleAlertNoticeClose() {
  return { type: HANDLE_ALERT_NOTICE_CLOSE };
}

export function checkSubscriptionOnLoad(subscriptionId) {
  return {
    type: CHECK_SUBSCRIPTION_ON_LOAD,
    subscriptionId,
    subscribed: _.isNumber(subscriptionId) && !_.isNaN(subscriptionId)
  };
}

export function handleCheckSubscriptionOnLoadError() {
  return {
    type: HANDLE_CHECK_SUBSCRIPTION_ON_LOAD_ERROR
  };
}

export function publishView() {
  return (dispatch, getState) => {
    const viewId = getState().view.id;
    const fetchOptions = {
      method: 'POST',
      headers: {
        ...defaultHeaders,
        'X-Socrata-Federation': 'Honey Badger'
      },
      credentials: 'same-origin'
    };

    dispatch(requestedViewPublish());

    return fetch(`/api/views/${viewId}/publication.json`, fetchOptions)
      .then(checkStatus)
      .then((response) => response.json())
      .then((publishedView) => {
        dispatch(handleViewPublishSuccess());

        redirectTo(window.location.href.replace(viewId, publishedView.id), 1000);
      })
      .catch(() => dispatch(handleViewPublishError()));
  };
}

export function setViewAnalysisId(analysisId) {
  return {
    type: SET_VIEW_ANALYSIS_ID,
    analysisId
  };
}

export function fetchViewAnalysisId() {
  return (dispatch, getState) => {
    const view = getState().view;
    return getAnalysisId(view.id)
      .then((analysisId) => {
        dispatch(setViewAnalysisId(analysisId));
      })
      .catch((err) => {
        console.debug(`No analysis found for ${view.id} - continuing`, err);
      });
  };
}

export function fetchRowCount() {
  return (dispatch, getState) => {
    const view = getState().view;
    const noRowCountConditions = [isBlob(view.coreView), isHref(view.coreView)];
    if (!noRowCountConditions.some(Boolean)) {
      const viewId = view.id;
      const fetchOptions = {
        method: 'GET',
        headers: {
          ...defaultHeaders,
          'X-Socrata-Federation': 'Honey Badger'
        },
        credentials: 'same-origin'
      };

      // NOTE: This is where a REQUESTED-type action would be dispatched, but
      // we're firing this on page load and it doesn't seem necessary.

      return fetch(`/api/id/${viewId}?$query=select count(*) as COLUMN_ALIAS_GUARD__count`, fetchOptions)
        .then(checkStatus)
        .then((response) => response.json())
        .then((apiResponse) => {
          // OBE will lowercase the field in the response.
          const rowCountOBE = _.get(apiResponse, '[0].column_alias_guard__count', null);
          const rowCountNBE = _.get(apiResponse, '[0].COLUMN_ALIAS_GUARD__count', null);

          let rowCount = null;
          if (_.isString(rowCountNBE)) {
            rowCount = _.toNumber(rowCountNBE);
          } else if (_.isString(rowCountOBE)) {
            rowCount = _.toNumber(rowCountOBE);
          }

          dispatch(handleFetchRowCountSuccess(rowCount));
        })
        .catch(() => dispatch(handleFetchRowCountError()));
    } else {
      //This view does not have rows
      dispatch(handleFetchRowCountSuccess(0));
    }
  };
}

export const checkSubscription = () => (dispatch, getState) => {
  if (_.get(window, 'sessionData.email', '') === '') {
    return;
  }
  const fetchOptions = {
    method: 'GET',
    headers: {
      ...defaultHeaders,
      'X-Socrata-Federation': 'Honey Badger'
    },
    credentials: 'same-origin'
  };
  const viewId = getState().view.id;
  const domainId = _.get(window, 'socrata.domain_id');
  const fetchUrl =
    '/api/notifications_and_alerts/subscriptions?domain_id=' +
    `${domainId}&dataset=${viewId}&activity=WATCH_DATASET`;

  return fetch(fetchUrl, fetchOptions)
    .then(checkStatus)
    .then((response) => response.json())
    .then((response) => {
      var subscriptionResponse = response.data;
      dispatch(checkSubscriptionOnLoad(_.get(subscriptionResponse[0], 'id', null)));
    })
    .catch(() => dispatch(handleCheckSubscriptionOnLoadError()));
};
