import React from 'react';

export const collectionParentLink = (collectionParent) => (
  <section className="landing-page-section href-download dataset-download-section">
    <div className="section-content">
      <div className="download-object">
        <div className="download-title">Associated with</div>
        <div className="download-buttons">
          <a
            className="btn btn-primary btn-sm download all-caps"
            id="collection-parent-link"
            href={`/d/${collectionParent.id}`}
            target="_blank"
            key={collectionParent.id}>
            {collectionParent.name}
          </a>
        </div>
      </div>
    </div>
  </section>
);
