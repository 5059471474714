// view
export const HANDLE_FETCH_ROW_COUNT_SUCCESS = 'HANDLE_FETCH_ROW_COUNT_SUCCESS';
export const HANDLE_FETCH_ROW_COUNT_ERROR = 'HANDLE_FETCH_ROW_COUNT_ERROR';
export const REQUESTED_VIEW_PUBLISH = 'REQUESTED_VIEW_PUBLISH';
export const HANDLE_VIEW_PUBLISH_SUCCESS = 'HANDLE_VIEW_PUBLISH_SUCCESS';
export const HANDLE_VIEW_PUBLISH_ERROR = 'HANDLE_VIEW_PUBLISH_ERROR';
export const CLEAR_VIEW_PUBLISH_ERROR = 'CLEAR_VIEW_PUBLISH_ERROR';
export const SET_VIEW_ANALYSIS_ID = 'SET_VIEW_ANALYSIS_ID';

// contactForm
export const SHOW_CONTACT_FORM_NOTIFICATION = 'SHOW_CONTACT_FORM_NOTIFICATION';
export const HIDE_CONTACT_FORM_NOTIFICATION = 'HIDE_CONTACT_FORM_NOTIFICATION';
export const SET_CONTACT_FORM_FIELD = 'SET_CONTACT_FORM_FIELD';
export const SET_CONTACT_FORM_RECAPTCHA_LOADED = 'SET_CONTACT_FORM_RECAPTCHA_LOADED';
export const SET_CONTACT_FORM_ERRORS = 'SET_CONTACT_FORM_ERRORS';
export const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM';
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';
export const HANDLE_CONTACT_FORM_SUCCESS = 'HANDLE_CONTACT_FORM_SUCCESS';
export const HANDLE_CONTACT_FORM_FAILURE = 'HANDLE_CONTACT_FORM_FAILURE';
export const HANDLE_CONTACT_FORM_RECAPTCHA_ERROR = 'HANDLE_CONTACT_FORM_RECAPTCHA_ERROR';
export const HANDLE_CONTACT_FORM_RECAPTCHA_RESET = 'HANDLE_CONTACT_FORM_RECAPTCHA_RESET';

// relatedViews
export const TOGGLE_RELATED_VIEWS = 'TOGGLE_RELATED_VIEWS';
export const REQUEST_RELATED_VIEWS = 'REQUEST_RELATED_VIEWS';
export const RECEIVE_RELATED_VIEWS = 'RECEIVE_RELATED_VIEWS';
export const HANDLE_RELATED_VIEWS_ERROR = 'HANDLE_RELATED_VIEWS_ERROR';
export const DISMISS_RELATED_VIEWS_ERROR = 'DISMISS_RELATED_VIEWS_ERROR';

// featuredContent
export const ADD_FEATURED_ITEM = 'ADD_FEATURED_ITEM';
export const EDIT_FEATURED_ITEM = 'EDIT_FEATURED_ITEM';
export const CANCEL_FEATURED_ITEM_EDIT = 'CANCEL_FEATURED_ITEM_EDIT';
export const REQUESTED_FEATURED_ITEM_SAVE = 'REQUESTED_FEATURED_ITEM_SAVE';
export const HANDLE_FEATURED_ITEM_SAVE_SUCCESS = 'HANDLE_FEATURED_ITEM_SAVE_SUCCESS';
export const HANDLE_FEATURED_ITEM_SAVE_ERROR = 'HANDLE_FEATURED_ITEM_SAVE_ERROR';
// featuredContent: External
export const SET_EXTERNAL_RESOURCE_FIELD = 'SET_EXTERNAL_RESOURCE_FIELD';
// featuredContent: Stories
export const SET_STORY_URL_FIELD = 'SET_STORY_URL_FIELD';
export const REQUESTED_STORY = 'REQUESTED_STORY';
export const HANDLE_LOADING_STORY_ERROR = 'HANDLE_LOADING_STORY_ERROR ';
export const HANDLE_LOADING_STORY_SUCCESS = 'HANDLE_LOADING_STORY_SUCCESS';
// featuredContent: Delete
export const REQUESTED_FEATURED_ITEM_REMOVAL = 'REQUESTED_FEATURED_ITEM_REMOVAL';
export const HANDLE_FEATURED_ITEM_REMOVAL_SUCCESS = 'HANDLE_FEATURED_ITEM_REMOVAL_SUCCESS';
export const HANDLE_FEATURED_ITEM_REMOVAL_ERROR = 'HANDLE_FEATURED_ITEM_REMOVAL_ERROR';
// featuredContent: Internal Assets
export const SET_SAVING_FEATURED_ITEM = 'SET_SAVING_FEATURED_ITEM';
export const REQUESTED_DERIVED_VIEWS = 'REQUESTED_DERIVED_VIEWS';
export const HANDLE_DERIVED_VIEWS_REQUEST_ERROR = 'HANDLE_DERIVED_VIEWS_REQUEST_ERROR';
export const RECEIVE_DERIVED_VIEWS = 'RECEIVE_DERIVED_VIEWS';

// subscriptions
export const ON_SUBSCRIPTION_CHANGE = 'ON_SUBSCRIPTION_CHANGE';
export const CHECK_SUBSCRIPTION_ON_LOAD = 'CHECK_SUBSCRIPTION_ON_LOAD';
export const HANDLE_CHECK_SUBSCRIPTION_ON_LOAD_ERROR = 'HANDLE_CHECK_SUBSCRIPTION_ON_LOAD_ERROR';

// Create Alert
export const HANDLE_ALERT_NOTICE_CLOSE = 'HANDLE_ALERT_NOTICE_CLOSE';
export const HANDLE_ALERT_SAVE_SUCCESS = 'HANDLE_ALERT_SAVE_SUCCESS';
