import _ from 'lodash';

import { RELATED_VIEWS_FETCH_LIMIT } from '../lib/constants';

import {
  REQUEST_RELATED_VIEWS,
  RECEIVE_RELATED_VIEWS,
  HANDLE_RELATED_VIEWS_ERROR,
  DISMISS_RELATED_VIEWS_ERROR,
  TOGGLE_RELATED_VIEWS
} from '../actionTypes';

const initialRelatedViews = _.get(window.initialState, 'relatedViews', []);
const initialRelatedViewCount = _.get(window.initialState, 'totalRelatedViewCount', 0);
const initialState = {
  viewList: initialRelatedViews,
  relatedViewCount: initialRelatedViewCount,
  hasMore: initialRelatedViewCount > RELATED_VIEWS_FETCH_LIMIT,
  hasError: false,
  isLoading: false,
  isCollapsed: false
};

const handleReceiveRelatedViews = (state, action) => {
  const fetchedViews = action.relatedViews;
  const viewList = action.resetViews ? fetchedViews : _.clone(state.viewList).concat(fetchedViews);

  return {
    ...state,
    viewList,
    relatedViewCount: action.relatedViewCount,
    hasMore: action.relatedViewCount > viewList.length,
    hasError: false,
    isLoading: false,
    isCollapsed: action.resetViews ? false : state.isCollapsed
  };
};

export default function (state, action) {
  if (_.isUndefined(state)) {
    return initialState;
  }

  switch (action.type) {
    case REQUEST_RELATED_VIEWS:
      return {
        ...state,
        isLoading: true
      };

    case RECEIVE_RELATED_VIEWS:
      return handleReceiveRelatedViews(state, action);
    case HANDLE_RELATED_VIEWS_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false
      };

    case DISMISS_RELATED_VIEWS_ERROR:
      return {
        ...state,
        hasError: false
      };

    case TOGGLE_RELATED_VIEWS:
      return {
        ...state,
        isCollapsed: !state.isCollapsed
      };

    default:
      return state;
  }
}
