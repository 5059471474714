import _ from 'lodash';
window._ = _;
import React from 'react';
import ReactDOM from 'react-dom';
import { attachTo } from 'common/components/legacy';
import { Provider } from 'react-redux';
import airbrake from 'common/airbrake';
import { isDevelopmentEnvironment } from 'common/environment';
import dslpCrossOriginErrorsFilter from 'common/airbrake/filters/dslp_cross_origin_errors';
import store from './store';
import Root from './root';
import DynamicContent from './DynamicContent';

// Register with Airbrake in non-dev environments.

if (!isDevelopmentEnvironment()) {
  airbrake.init(window.serverConfig.airbrakeProjectId, window.serverConfig.airbrakeKey);
  airbrake.addFilter(dslpCrossOriginErrorsFilter);
}

// Defer rendering so the spinner in the erb can render.
_.defer(() => {
  if (window.lastAccessed) { window.lastAccessed.add(window.initialState.view.id); }

  // Render the App, falling back to rendering an error if it fails.
  try {
    ReactDOM.render(
      <Root store={store} />,
      document.querySelector('#app')
    );
  } catch (e) {
    console.error('Fatal error when rendering:', e);

    ReactDOM.render(
      <div className="alert error alert-full-width-top">{I18n.render_error}</div>,
      document.querySelector('#app')
    );

    return;
  }

  // Show the footer
  const footer = document.querySelector('#site-chrome-footer');
  if (footer) {
    footer.style.visibility = 'visible';
  }

  // Flush the app to the browser and render the modals, flannels, etc.
  _.defer(() => {
    ReactDOM.render(
      <Provider store={store}>
        <DynamicContent />
      </Provider>,
      document.querySelector('#dynamic-content')
    );

    // Initialize the javascript components
    attachTo(document.querySelector('.dataset-landing-page'));
  });
});
