import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import {
  ForgeCard,
  ForgeDivider,
} from '@tylertech/forge-react';
import { get as _get, debounce as _debounce } from 'lodash';
import I18n, { categoryDisplay } from 'common/i18n';
import { DatasetLandingPageEnhancedView } from 'common/types/view';
import { formatDateWithLocale } from 'common/dates';
import AssetBadgesSection from 'common/components/AssetBadgeSection/AssetBadgeSection';
import ExpandableTextSection from 'common/components/ExpandableTextSection';
import { localizeLink } from 'common/locale';

const t = (k: string, scope = 'dataset_landing_page.info_pane') => I18n.t(k, { scope });

type NewInfoPaneProps = {
  name: string;
  category?: string;
  description?: string;
  attribution?: string;
  lastUpdatedAt?: string;
  dataSourceDetails: null | {
    parentViewId: string;
    parentViewName: string;
  };
  provenance: string;
};

export const NewInfoPane: FunctionComponent<NewInfoPaneProps> = ({
  name,
  category,
  description,
  attribution,
  lastUpdatedAt,
  dataSourceDetails,
  provenance
}) => {
  const categories = category ? [category] : undefined;

  const parentViewLink = dataSourceDetails ? (
    <div className="parent-view-link">
      {t('view_based_on')}
      <a href={localizeLink(`/d/${dataSourceDetails.parentViewId}`)}>{dataSourceDetails.parentViewName}</a>
    </div>
  ) : null;
  return (
    <section className="landing-page-section">
      <ForgeCard outlined className="new-info-pane">
        <div className="upper-info-pane">
          <div className="name-and-badges-section">
            <h2 className="asset-name">{name}</h2>
            <AssetBadgesSection categories={categories} provenance={provenance} />
          </div>
          {parentViewLink}
        </div>
        <ForgeDivider />
        <div className="lower-info-pane">
          <div className='description-section'>
            <ExpandableTextSection bodyText={description} noTextMessage={t('no_description_provided')} countOfLinesToShow={4}/>
          </div>
          <MetadataSection lastUpdatedAt={lastUpdatedAt} dataProvidedBy={attribution} />
        </div>
      </ForgeCard>
    </section>
  );
};

type MetadataSectionProps = {
  lastUpdatedAt?: string;
  dataProvidedBy?: string;
};

const MetadataSection: FunctionComponent<MetadataSectionProps> = ({ lastUpdatedAt, dataProvidedBy }) => {
  const displayFormattedLastUpdatedAt = formatDateWithLocale(lastUpdatedAt);

  const dataProvidedBySection = dataProvidedBy ? (
    <div className="metadata-row">
      <span className="metadata-label">{t('data_provided_by')}</span>
      <span className="date">{dataProvidedBy}</span>
    </div>
  ) : null;

  return (
    <div className="metadata-section">
      <div className="metadata-top-row">
        <span className="metadata-label">{t('last_updated')}</span>
        <span className="date">{displayFormattedLastUpdatedAt}</span>
      </div>
      {dataProvidedBySection}
    </div>
  );
};

const mapStateToProps = ({ view }: { view: DatasetLandingPageEnhancedView }) => {
  const { id: parentViewId, name: parentViewName } = _get(view, 'parentView') || {};
  const dataSourceDetails =
    parentViewId && parentViewName
      ? {
          parentViewName,
          parentViewId
        }
      : null;

  return {
    name: view.name,
    category: view.category,
    description: view.description,
    attribution: view.attribution,
    lastUpdatedAt: view.lastUpdatedAt,
    dataSourceDetails,
    provenance: view.provenance
  };
};

export default connect(mapStateToProps)(NewInfoPane);
