import _ from 'lodash';
import I18n from 'common/i18n';
import React from 'react';
import { currentUserHasRight } from 'common/current_user';
import * as Rights from 'common/rights';
import { showAccessManager } from '../lib/accessManager';
import { localizeLink } from 'common/locale';
import { ForgeDialog, ForgeIcon, ForgeIconButton, ForgeScaffold, ForgeToolbar } from '@tylertech/forge-react';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'dataset_landing_page', ...options });

const renderPrivateNotice = (view: any, onDismiss: () => void) => {
  if (view.isPrivate) {
    const manageLink = currentUserHasRight(Rights.EDIT_OTHERS_DATASETS) ? (
      <a href={`${localizeLink(view.gridUrl)}?pane=manage`} onClick={(e) => showAccessManager(e, onDismiss)}>
        {t('manage_prompt')}
      </a>
    ) : null;

    return (
      <section>
        <div className="alert info">
          <span dangerouslySetInnerHTML={{ __html: t('share.visibility_alert_html') }} /> {manageLink}
        </div>
      </section>
    );
  } else {
    return null;
  }
};

const onClickOption = (event: any, onClickShareOption: (e: any) => void) => {
  onClickShareOption(event.currentTarget.dataset.shareOption);
};

interface ShareModalProps {
  view: any;
  onDismiss: () => void;
  onClickShareOption: (e: any) => void;
}

const ShareModal = ({ view, onDismiss, onClickShareOption }: ShareModalProps) => {
  return (
    <ForgeDialog open={true} onDismiss={onDismiss}>
      <div id="share-dialog" data-testid="share-dialog">
        <ForgeScaffold>
          <div slot="header" className="share-dialog-header">
            <ForgeToolbar>
              <h1 slot="start" id="share-dialog-title" className="forge-typography--headline5">
                {t('share.asset')}
              </h1>
              <ForgeIconButton slot="end">
                <button className="tyler-icons" onClick={onDismiss}>
                  <ForgeIcon name="close" />
                </button>
              </ForgeIconButton>
            </ForgeToolbar>
          </div>
          <div slot="body" className="share-dialog-container">
            <div>{t('share.description', { dataset_title: view.name })}</div>
            {renderPrivateNotice(view, onDismiss)}

            <div className="section">
              <div className="facebook">
                <a
                  href={view.facebookShareUrl}
                  data-share-option="Facebook"
                  target="_blank"
                  data-testid="share-on-facebook-link"
                  onClick={(e) => onClickOption(e, onClickShareOption)}
                >
                  <span className="icon-facebook" />
                  {t('share.facebook')}
                </a>
              </div>

              <div className="twitter">
                <a
                  href={view.twitterShareUrl}
                  data-share-option="Twitter"
                  target="_blank"
                  data-testid="share-on-twitter-link"
                  onClick={(e) => onClickOption(e, onClickShareOption)}
                >
                  <span className="icon-twitter" />
                  {t('share.twitter')}
                </a>
              </div>

              <div className="email">
                <a
                  href={view.emailShareUrl}
                  data-share-option="Email"
                  data-testid="share-via-email-link"
                  onClick={(e) => onClickOption(e, onClickShareOption)}
                >
                  <span className="icon-email" />
                  {t('share.email')}
                </a>
              </div>
            </div>
          </div>
        </ForgeScaffold>
      </div>
    </ForgeDialog>
  );
};

export default ShareModal;
