import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { components as SocrataVisualizations } from 'common/visualizations';
import SearchInput from 'common/components/SearchInput';
import { getSiteChromeHeight, getAssetBarHeight } from 'common/util/siteMeasurements';
import I18n from 'common/i18n';
import AssetBadgeSection from 'common/components/AssetBadgeSection/AssetBadgeSection';
import { View } from 'common/types/view';
import { Vif } from 'common/visualizations/vif';

const t = (k: string, scope = 'dataset_landing_page.dataset_preview') => I18n.t(k, { scope });

// Decide if we'll use setFilter based on base row count of the view
// too many possible values makes load time and interaction too slow.
export const ROW_COUNT_CAP_FOR_SET_FILTERS = 100000;

interface DataSourceDetails {
  parentViewName: string;
  parentViewId: string;
}

interface DatasetPreviewProps {
  view: View;
  rowLabel: string;
  rowLabelMultiple: string;
  rowCount?: number;
  onVifUpdate: () => void;
  dataSourceDetails?: DataSourceDetails;
}

type TimeoutId = number;

export const DatasetPreview: FunctionComponent<DatasetPreviewProps> = ({
  view,
  onVifUpdate,
  dataSourceDetails,
  rowCount,
  rowLabel,
  rowLabelMultiple
}) => {
  const [searchText, setSearchText] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<TimeoutId>(0);
  const [currentSearchString, setCurrentSearchString] = useState('');

  let vif: Vif = {
    format: {
      type: 'visualization_interchange_format',
      version: 3
    },
    configuration: {
      viewSourceDataLink: false
    },
    series: [
      {
        dataSource: {
          datasetUid: view.id,
          dimension: { columns: view.columns },
          type: 'socrata.soql',
          filters: [],
          hierarchies: [],
          searchString: ''
        },
        type: 'agTable',
        unit: {
          one: rowLabel,
          other: rowLabelMultiple
        }
      }
    ]
  };

  useEffect(() => {
    renderTable();
    // this horrible hack of selection is needed because of a strange Forge interaction with the
    // AgGrid. After a search, the input box would still have the cursor and accept typing but
    // would no longer be "focused," thus losing its highlighting css.
    const element = document.activeElement?.parentElement?.shadowRoot?.querySelector('.forge-field');
    if (element) {
      element.classList.add('forge-field--focused');
    }
  }, [currentSearchString]);

  const onChange = (value: string) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setSearchText(value);
    // window is needed because otherwise typescript resolves setTimeout to the one from node
    setTypingTimeout(
      window.setTimeout(() => {
        setCurrentSearchString(value);
      }, 500)
    );
  };

  const renderSearchBar = () => {
    return (
      <SearchInput
        onSearch={(value) => {
          onChange(value);
        }}
        onChange={onChange}
        value={searchText}
        title={t('search')}
        id="search-view"
        dataTestId="search-primer-table-input"
      />
    );
  };

  const renderTable = () => {
    // add the searchString to the vif
    if (vif.series) {
      vif = {
        ...vif,
        series: [
          {
            ...vif.series[0],
            // TODO: we should reconsider searchString being positioned here
            // search string is not on the vif type but its there
            // @ts-expect-error TS(2339) FIXME: Property 'searchString' does not exist on type 'Da... Remove this comment to see the full error message
            dataSource: { ...vif.series[0].dataSource, searchString: currentSearchString }
          }
        ]
      };
    }

    const defaultColDefOverrides = {
      maxWidth: 5000 // we have to give it a max size so setting it absurdly high
    };

    // This is a temporary way to pass localization information to frontend-visualizations
    // to localize the Table & Pager until the mono-repo is complete.
    const localeOptions = _.has(window, 'serverConfig.locale')
      ? { locale: window.serverConfig?.locale }
      : { locale: '' };
    const options = {
      ...localeOptions,
      paginationPageSize: 50,
      defaultColDefOverrides,
      onAgTableVifUpdate: onVifUpdate,
      displayColumnFilters: true,
      useSetFilters: !!rowCount && rowCount <= ROW_COUNT_CAP_FOR_SET_FILTERS,
      showAgGridColumnMenu: true,
      showAgGridColumnAggregations: false
    };

    return (
      <div className="table-contents-primer-improved">
        <SocrataVisualizations.Visualization vif={vif} options={options} />
      </div>
    );
  };

  const isChildView = !!dataSourceDetails;

  const getHeaderOffset = () => {
    const getTabBarHeight = () => {
      const tabBar = document.getElementById('view-switcher-nav-bar');
      if (tabBar) {
        return tabBar.offsetHeight;
      } else {
        return 0;
      }
    };

    const actionBarHeight = getAssetBarHeight();
    const siteChromeHeaderHeight = getSiteChromeHeight();
    const tabBarHeight = getTabBarHeight();
    const totalOffSet = siteChromeHeaderHeight + tabBarHeight + actionBarHeight;
    return { height: `calc(100vh - ${totalOffSet}px)` };
  };

  const parentViewLink = isChildView ? (
    <div className="landing-page-section-header-parent-link">
      {t('view_based_on')}&nbsp;
      <a href={`/d/${dataSourceDetails.parentViewId}`}>{dataSourceDetails.parentViewName}</a>
    </div>
  ) : null;

  return (
    <div
      className="landing-page-section dataset-preview landing-page-section-primer-improved"
      style={getHeaderOffset()}
    >
      <div className={'landing-page-header-wrapper-primer-improved'}>
        <div className="landing-page-section-header-primer-improved">
          <span className={isChildView ? 'has-parent-view' : ''}>
            <h2 className="landing-page-section-header-title">{view.name}</h2>
            <span className="landing-page-section-header-badge">
              <AssetBadgeSection provenance={view.provenance} />
            </span>
          </span>
          {parentViewLink}
          <span className="right-content">{renderSearchBar()}</span>
        </div>
      </div>
      {renderTable()}
    </div>
  );
};
