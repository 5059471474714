import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { hasRowEditRights, hasUpdateViewRight } from 'common/views/has_rights';
import { currentUserHasRight } from 'common/current_user';
import * as Rights from 'common/rights';

import FeaturedViewCard from './FeaturedViewCard';

export class FeaturedContent extends Component {
  renderManagePrompt() {
    const { isBlobby, isHref, showManageFeaturedContentButton } = this.props;

    if (!showManageFeaturedContentButton) {
      return null;
    }

    const translationKey = (isBlobby || isHref) ? 'message_external' : 'message';
    const message = I18n.featured_content.manage_prompt[translationKey];

    return (
      <div className="alert default manage-prompt">
        <span className="manage-prompt-message">
          {message}
        </span>

        <button
          className="btn btn-sm btn-default manage-prompt-button"
          data-testid="manage-content-button"
          data-modal="featured-content-modal">
          {I18n.featured_content.manage_prompt.button}
        </button>
      </div>
    );
  }

  renderFeaturedContent() {
    const { contentList } = this.props;

    if (_.every(contentList, _.isNull)) {
      return null;
    }

    const cards = _.map(_.compact(contentList), (featuredItem, i) =>
      <FeaturedViewCard key={i} featuredItem={featuredItem} />
    );

    return <div className="media-results">{cards}</div>;
  }

  render() {
    const { contentList, showManageFeaturedContentButton } = this.props;

    if (!_.some(contentList) && !showManageFeaturedContentButton) {
      return null;
    }

    return (
      <section className="landing-page-section featured-content">
        <h2 className="landing-page-section-header">
          {I18n.featured_content.title}
        </h2>

        {this.renderManagePrompt()}
        {this.renderFeaturedContent()}
      </section>
    );
  }
}

FeaturedContent.propTypes = {
  contentList: PropTypes.array.isRequired,
  isBlobby: PropTypes.bool,
  isHref: PropTypes.bool,
  /**
   * Whether or not we should show the "manage featured content" button.
   */
  userCanManageFeaturedContent: PropTypes.bool
};

export function mapStateToProps(state) {
  const { coreView } = state.view;

  const showManageFeaturedContentButton =
    currentUserHasRight(Rights.FEATURE_ITEMS) && // Domain level right.
    (hasUpdateViewRight(coreView) || hasRowEditRights(coreView)); // Asset level permissions.

  return {
    isBlobby: state.view.isBlobby,
    isHref: state.view.isHref,
    contentList: state.featuredContent.contentList,
    showManageFeaturedContentButton
  };
}

export default connect(mapStateToProps)(FeaturedContent);
