import _ from 'lodash';
import {
  CHECK_SUBSCRIPTION_ON_LOAD,
  CLEAR_VIEW_PUBLISH_ERROR,
  HANDLE_ALERT_NOTICE_CLOSE,
  HANDLE_ALERT_SAVE_SUCCESS,
  HANDLE_CHECK_SUBSCRIPTION_ON_LOAD_ERROR,
  HANDLE_FETCH_ROW_COUNT_ERROR,
  HANDLE_FETCH_ROW_COUNT_SUCCESS,
  HANDLE_VIEW_PUBLISH_ERROR,
  HANDLE_VIEW_PUBLISH_SUCCESS,
  ON_SUBSCRIPTION_CHANGE,
  REQUESTED_VIEW_PUBLISH,
  SET_VIEW_ANALYSIS_ID
} from '../actionTypes';

function getInitialState() {
  return _.extend({}, _.get(window.initialState, 'view', {}), {
    isPublishing: false,
    hasPublishingError: false,
    hasPublishingSuccess: false
  });
}

export default function(state, action) {
  if (_.isUndefined(state)) {
    return getInitialState();
  }

  switch (action.type) {
    case REQUESTED_VIEW_PUBLISH:
      return {
        ...state,
        isPublishing: true,
        hasPublishingSuccess: false,
        hasPublishingError: false
      };

    case HANDLE_VIEW_PUBLISH_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        hasPublishingSuccess: true,
        hasPublishingError: false
      };

    case HANDLE_VIEW_PUBLISH_ERROR:
      return {
        ...state,
        isPublishing: false,
        hasPublishingSuccess: false,
        hasPublishingError: true
      };

    case CLEAR_VIEW_PUBLISH_ERROR:
      return {
        ...state,
        hasPublishingError: false
      };

    case SET_VIEW_ANALYSIS_ID:
      return {
        ...state,
        analysisId: action.analysisId
      };

    case ON_SUBSCRIPTION_CHANGE:
      return {
        ...state,
        subscriptionId: action.subscriptionId,
        subscribed: action.subscribed
      };

    case CHECK_SUBSCRIPTION_ON_LOAD:
      return {
        ...state,
        subscriptionId: action.subscriptionId,
        subscribed: action.subscribed
      };
    case HANDLE_CHECK_SUBSCRIPTION_ON_LOAD_ERROR:
      return {
        ...state,
        subscribed: false,
        subscriptionId: null
      };
    case HANDLE_FETCH_ROW_COUNT_SUCCESS:
      return {
        ...state,
        rowCount: action.rowCount
      };

    case HANDLE_FETCH_ROW_COUNT_ERROR:
      return {
        ...state,
        rowCount: null
      };

    case HANDLE_ALERT_SAVE_SUCCESS:
      return {
        ...state,
        showAlertSavedNotice: true
      };

    case HANDLE_ALERT_NOTICE_CLOSE:
      return {
        ...state,
        showAlertSavedNotice: false
      };

    default:
      return state;
  }
}
