import React from 'react';
import { connect } from 'react-redux';
import I18n from 'common/i18n';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';

type ContactFormStatus = 'success' | 'failure' | 'sending';

interface ContactFormProps {
  previousStatus?: ContactFormStatus;
  notificationVisible: boolean;
  dispatch: any;
}

interface AppState {
  contactForm: ContactFormProps;
}

export function ContactFormNotice(props: ContactFormProps) {
  const { previousStatus: status, notificationVisible, dispatch } = props;

  if (!notificationVisible) {
    return null;
  }

  let text;
  // can we move this all to a new messaging component?
  let toastType;
  let duration;
  if (status === 'success') {
    text = I18n.t('dataset_landing_page.contact_dataset_owner_modal.success_message_sent');
    toastType = ToastType.FORGE_SUCCESS;
    duration = 5000;
  } else if (status === 'failure') {
    text = I18n.t('dataset_landing_page.contact_dataset_owner_modal.message_failed_to_send');
    toastType = ToastType.FORGE_ERROR;
    duration = Infinity;
  } else {
    return null;
  }

  showToastNow({
    type: toastType,
    content: text,
    forgeOptions: {
      duration,
      showClose: true
    }
  });
  return null;
}

function mapStateToProps(state: AppState) {
  const { notificationVisible, previousStatus } = state.contactForm;
  return { notificationVisible, previousStatus };
}

export default connect(mapStateToProps)(ContactFormNotice);
