import { ForgeButton, ForgeIcon, ForgeMenu, ForgeTooltip } from '@tylertech/forge-react';
import {
  IListItemComponent,
  IMenuOption,
  IMenuOptionGroup,
  IconComponentDelegate,
  IconExternalType
} from '@tylertech/forge';
import React from 'react';
import I18n from 'common/i18n';
import { shouldUseExploreInsteadOfGrid } from 'common/views/helpers';
import { DatasetLandingPageEnhancedView, View } from 'common/types/view';
import { connect } from 'react-redux';
import { localizeLink } from 'common/locale';
import subscriptionApi from 'common/components/MuteDatasetButton/subscriptionApi';
import { FeatureFlags } from 'common/feature_flags';
import { hasOwnerLikeRights } from 'common/views/has_rights';
import _ from 'lodash';
import get from 'lodash/get';
import { scgcEnabled } from 'common/domain/helpers';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '../a... Remove this comment to see the full error message
import { onSubscriptionChange as subscriptionChange } from '../actions/view';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './OD... Remove this comment to see the full error message
import ODataModal from './ODataModal';
import CreateAlertModal from 'common/components/CreateAlertModal';
import { isMobile } from 'common/visualizations/helpers/MediaQueryHelper';
import { launchScheduleModal } from 'common/components/ScheduleModal';
import { WATCH_DATASET_ACTIVITY } from 'datasetLandingPage/lib/constants';
import {
  showForgeErrorToastNow,
  showForgeSuccessToastNow
} from 'common/components/ToastNotification/Toastmaster';
import {
  currentUserIsSiteMember,
  currentUserIsSuperAdmin,
  currentUserIsLoggedIn,
  currentUserHasRight
} from 'common/current_user';
import DomainRights from 'common/types/domainRights';
import { refreshAnalysis } from 'common/program_analytics/helpers';
import ShareModal from './ShareModal';
import ShareAndEmbedComponent from 'common/ShareAndEmbedComponent';

const t = (str: string) => I18n.t(str, { scope: 'dataset_landing_page.action_button' });

const MUTE_DATASET_ACTIVITY = 'MUTE_DATASET';

export interface ActionButtonProps {
  view: DatasetLandingPageEnhancedView;
  openExportModalToApi: () => void;
}

interface State {
  viewId: number;
  muteId: number | null;
  isMuted: boolean;
  shareModalOpen: boolean;
  alertModalOpen: boolean;
}

interface DispatchProps {
  onSubscriptionChange: (v: number | null) => void;
}

type Props = ActionButtonProps & DispatchProps;

export enum PRIMER_ACTIONS {
  queryData = 'queryData',
  visualize = 'visualize',
  api = 'api',
  oData = 'oData',
  schedule = 'schedule',
  watchDataset = 'watchDataset',
  unWatchDataset = 'unWatchDataset',
  customAlert = 'customAlert',
  mute = 'mute',
  unmute = 'unmute',
  share = 'share',
  contact = 'contact',
  refresh = 'refresh'
}

export enum PRIMER_ACTIONS_VIZ {
  createViz = 'createViz',
  carto = 'carto',
  plotly = 'plotly',
  integrations = 'integrations'
}

interface ActionBarIcon {
  name: string;
  external: boolean;
  externalType: IconExternalType;
}

const actionBarIcons = {
  [PRIMER_ACTIONS.queryData]: { iconName: 'database_edit' },
  [PRIMER_ACTIONS.visualize]: { iconName: 'bar_chart' },
  [PRIMER_ACTIONS.api]: { iconName: 'api' },
  [PRIMER_ACTIONS.oData]: { iconName: 'file_table_box' },
  [PRIMER_ACTIONS.refresh]: { iconName: 'refresh' },
  [PRIMER_ACTIONS.schedule]: { iconName: 'clock_outline' },
  [PRIMER_ACTIONS.watchDataset]: { iconName: 'notifications_active' },
  [PRIMER_ACTIONS.unWatchDataset]: { iconName: 'notifications_off' },
  [PRIMER_ACTIONS.customAlert]: { iconName: 'add_alert' },
  [PRIMER_ACTIONS.mute]: { iconName: 'volume_mute' },
  [PRIMER_ACTIONS.unmute]: { iconName: 'volume_high' },
  [PRIMER_ACTIONS.share]: { iconName: 'share_variant' },
  [PRIMER_ACTIONS.contact]: { iconName: 'mail_outline' },
  [PRIMER_ACTIONS_VIZ.integrations]: { iconName: 'open_in_new' }
};

const actionBarSubtitles = {
  [PRIMER_ACTIONS.queryData]: t('sub_titles.group'),
  [PRIMER_ACTIONS.watchDataset]: t('sub_titles.notify'),
  [PRIMER_ACTIONS.unWatchDataset]: t('sub_titles.stop_notifications')
};

const getActionIcon = (action: PRIMER_ACTIONS | PRIMER_ACTIONS_VIZ): HTMLElement => {
  const props = {
    name: actionBarIcons[action].iconName
  };
  // The forge stuff is a bit misleading with its types so doing a cast here to avoid TS complaining
  return new IconComponentDelegate({ props }).element as unknown as HTMLElement;
};

const getScheduleParams = (coreView: View) => {
  const hasRights = hasOwnerLikeRights(coreView);
  const params = _.get(window, 'initialState.view.dsmapiAutomationGuidance.automation_params');

  if (hasRights) {
    return params;
  } else {
    return null;
  }
};

class ActionButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      viewId: get(window, 'sessionData.viewId'),
      muteId: null,
      isMuted: false,
      shareModalOpen: false,
      alertModalOpen: false
    };
    _.bindAll(this, ['closeShareModal', 'closeAlertModal']);
  }

  componentDidMount() {
    // check if the asset is muted
    subscriptionApi
      .checkSubscription(this.state.viewId, MUTE_DATASET_ACTIVITY)
      .then((response: any) => {
        const muteId = get(response, '[0].id', null);
        this.setState({
          muteId,
          isMuted: muteId ? true : false
        });
      })
      .catch(() => {
        this.setState({ isMuted: false });
      });
  }

  // This is used to create the titles and subtitles in the menu
  // note since we are using the optionBuilder we have to actually build all the options in the menu.
  optionBuilder = (option: IMenuOption, listItemEl: IListItemComponent) => {
    // helper func to append elements with casting as forge is a bit weird with its types
    const appendElement = (el: HTMLSpanElement) => (listItemEl as unknown as HTMLElement).appendChild(el);

    if (!option.value && !option.options) {
      // title section - static with no value
      const subtitleEl = document.createElement('span');
      subtitleEl.slot = 'subtitle';
      subtitleEl.textContent = option.label;
      listItemEl.static = true;
      appendElement(subtitleEl);
    } else {
      // regular elements
      const titleEl = document.createElement('span');
      titleEl.slot = 'title';
      titleEl.textContent = option.label;
      const optionId = 'action-list-option-' + option.value;
      titleEl.id = optionId;

      // add test id
      titleEl.setAttribute('data-testid', optionId);

      appendElement(titleEl);

      if (
        [PRIMER_ACTIONS.queryData, PRIMER_ACTIONS.watchDataset, PRIMER_ACTIONS.unWatchDataset].includes(
          option.value
        )
      ) {
        // add a subtitle
        const subtitleEl = document.createElement('span');
        subtitleEl.slot = 'subtitle';
        subtitleEl.textContent = actionBarSubtitles[option.value];
        appendElement(subtitleEl);
        listItemEl.twoLine = true;
      }
    }
  };

  useDataAssetStrings() {
    const { view } = this.props;
    return FeatureFlags.value('usaid_features_enabled') && view.metadata && view.metadata.isParent === true;
  }

  showCreateAlerts = () => {
    const enableCreateAlertButtonForAllUser =
      FeatureFlags.value('enable_alert_notifications_for_all_users') === true;
    const enableCreateAlertButtonForAdmin =
      FeatureFlags.value('enable_alert_notifications_for_admin_users') === true;
    const enableCreateAlertButtonForRolledUser =
      FeatureFlags.value('enable_alert_notifications_for_role_users') === true;
    let showCreateAlerts = false;

    if (enableCreateAlertButtonForAllUser) {
      showCreateAlerts = true;
    } else if (
      enableCreateAlertButtonForAdmin &&
      (currentUserIsSuperAdmin() || currentUserHasRight(DomainRights.manage_users))
    ) {
      showCreateAlerts = true;
    } else if (
      enableCreateAlertButtonForRolledUser &&
      (currentUserIsSiteMember() || currentUserIsSuperAdmin())
    ) {
      showCreateAlerts = true;
    }

    return showCreateAlerts;
  };

  getWatchDatasetOption = (): IMenuOption<string> => {
    const { view } = this.props;
    let watchDatasetText;

    if (this.useDataAssetStrings()) {
      watchDatasetText = view.subscribed ? t('menu_entires.stop_watch_asset') : t('menu_entires.watch_asset');
    } else {
      watchDatasetText = view.subscribed ? t('menu_entires.stop_watch') : t('menu_entires.watch');
    }

    if (view.subscribed) {
      return {
        label: watchDatasetText,
        value: PRIMER_ACTIONS.unWatchDataset,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.unWatchDataset)
      };
    } else {
      return {
        label: watchDatasetText,
        value: PRIMER_ACTIONS.watchDataset,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.watchDataset)
      };
    }
  };

  getActionBarMenu = (): (IMenuOption | IMenuOptionGroup)[] => {
    const { view } = this.props;
    const menuOptions: (IMenuOption | IMenuOptionGroup)[] = [];

    const basicDatasetOptions: (IMenuOption | IMenuOptionGroup)[] = [
      {
        label: t('menu_entires.query_data'),
        value: PRIMER_ACTIONS.queryData,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.queryData)
      },
      ...this.getActionBarVizSubmenu(),
      {
        label: t('menu_entires.api'),
        value: PRIMER_ACTIONS.api,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.api)
      },
      {
        label: t('menu_entires.odata'),
        value: PRIMER_ACTIONS.oData,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.oData)
      }
    ];

    const hrefOrBlob = !!view.isHref || !!view.isBlobby;

    if (!hrefOrBlob) menuOptions.push(...basicDatasetOptions);

    if (view.analysisId) {
      menuOptions.push({
        label: t('menu_entires.refresh'),
        value: PRIMER_ACTIONS.refresh,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.refresh)
      });
    }

    // Notifications and alerts section
    menuOptions.push(...this.getNotificationsAndAlertsMenuSection());

    // Social and contact section
    menuOptions.push(...this.getSocialAndEmailMenuSection());

    return menuOptions;
  };

  getNotificationsAndAlertsMenuSection = (): IMenuOption[] => {
    const menuOptions: IMenuOption[] = [];
    const { view } = this.props;
    const enableNotifications = _.get(window, 'serverConfig.enableNotifications');
    const currentUserLoggedIn = currentUserIsLoggedIn();

    // only show schedule if we have schedule params
    if (view.coreView && getScheduleParams(view.coreView)) {
      menuOptions.push({
        label: t('menu_entires.schedule'),
        value: PRIMER_ACTIONS.schedule,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.schedule)
      });
    }

    // check if we can email stuff
    if (_.get(window, 'sessionData.email', '') !== '' && enableNotifications) {
      const watchDatasetItem = this.getWatchDatasetOption();
      menuOptions.push(watchDatasetItem);
    }

    if (enableNotifications && currentUserLoggedIn) {
      // Custom alerts
      if (this.showCreateAlerts()) {
        menuOptions.push({
          label: t('menu_entires.alert'),
          value: PRIMER_ACTIONS.customAlert,
          leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.customAlert)
        });
      }

      // Mute
      const muteOption: IMenuOption = this.state.isMuted
        ? {
            label: t('menu_entires.unmute'),
            value: PRIMER_ACTIONS.unmute,
            leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.unmute)
          }
        : {
            label: t('menu_entires.mute'),
            value: PRIMER_ACTIONS.mute,
            leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.mute)
          };
      menuOptions.push(muteOption);
    }

    // add the divider and title if there are any options in the list
    if (menuOptions.length) {
      menuOptions.unshift(
        ...[
          { label: '', value: null, divider: true },
          { label: t('menu_entires.notifications'), value: null }
        ]
      );
    }

    return menuOptions;
  };

  getSocialAndEmailMenuSection = (): IMenuOption[] => {
    const menuOptions: IMenuOption[] = [];
    const { view } = this.props;
    // EN-22065: Don't render share link if on SCGC (EDP) domain and the view is private
    if (!(view.isPrivate && scgcEnabled())) {
      menuOptions.push({
        label: FeatureFlags.value('share_and_embed_tabular_data')
          ? t('menu_entires.share_and_embed')
          : t('menu_entires.share'),
        value: PRIMER_ACTIONS.share,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.share)
      });
    }

    if (!view.disableContactDatasetOwner) {
      menuOptions.push({
        label: t('menu_entires.contact'),
        value: PRIMER_ACTIONS.contact,
        leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.contact)
      });
    }

    // add divider if there is at least one entry here
    if (menuOptions.length) menuOptions.unshift({ label: '', value: null, divider: true });

    return menuOptions;
  };

  getActionBarVizSubmenu = (): IMenuOption[] => {
    const enableStandaloneVisualizationCreation = FeatureFlags.value(
      'enable_standalone_visualization_creation'
    );

    if (!enableStandaloneVisualizationCreation) return [];

    const externalDataIntegrationsEnabled = FeatureFlags.value('enable_external_data_integrations');
    const vizOptions: IMenuOption[] = enableStandaloneVisualizationCreation
      ? [{ label: t('menu_entires.create_visualization'), value: PRIMER_ACTIONS_VIZ.createViz }]
      : [];

    if (!this.props.view.isPrivate && externalDataIntegrationsEnabled) {
      vizOptions.push({ label: t('menu_entires.open_visualization'), value: null });
      if (this.props.view.cartoUrl)
        vizOptions.push({ label: t('menu_entires.carto'), value: PRIMER_ACTIONS_VIZ.carto });

      vizOptions.push({ label: t('menu_entires.plotly'), value: PRIMER_ACTIONS_VIZ.plotly });

      // When on mobile we don't show the vizualization items in their own submenu, so this divider is confusing.
      if (!isMobile()) {
        vizOptions.push({ label: '', value: null, divider: true });
      }

      vizOptions.push({
        label: t('menu_entires.integrations'),
        value: PRIMER_ACTIONS_VIZ.integrations,
        trailingBuilder: () => getActionIcon(PRIMER_ACTIONS_VIZ.integrations)
      });
    }

    // When on mobile the screen isn't big enough to show a submenu.
    // In this case we'll just wrap it in dividers and treat it as a top level menu section.
    if (isMobile()) {
      return [
        { label: '', value: null, divider: true },
        ...vizOptions,
        { label: '', value: null, divider: true }
      ];
    } else {
      return [
        {
          label: t('menu_entires.visualize'),
          value: PRIMER_ACTIONS.visualize,
          options: vizOptions,
          leadingBuilder: () => getActionIcon(PRIMER_ACTIONS.visualize)
        }
      ];
    }
  };

  handleMenuSelect = (action: PRIMER_ACTIONS | PRIMER_ACTIONS_VIZ) => {
    switch (action) {
      case PRIMER_ACTIONS.queryData: {
        this.navQueryData();
        break;
      }
      case PRIMER_ACTIONS.api: {
        this.openApiFlannel();
        break;
      }
      case PRIMER_ACTIONS.oData: {
        this.openODataModal();
        break;
      }
      case PRIMER_ACTIONS.refresh: {
        refreshAnalysis(this.props.view.id);
        break;
      }
      case PRIMER_ACTIONS.schedule: {
        this.openScheduleUpdates();
        break;
      }
      case PRIMER_ACTIONS.watchDataset: {
        this.watchDataset();
        break;
      }
      case PRIMER_ACTIONS.unWatchDataset: {
        this.watchDataset();
        break;
      }
      case PRIMER_ACTIONS.customAlert: {
        this.openAlertModalOpen();
        break;
      }
      case PRIMER_ACTIONS.mute: {
        this.muteDataset();
        break;
      }
      case PRIMER_ACTIONS.unmute: {
        this.muteDataset();
        break;
      }
      case PRIMER_ACTIONS.share: {
        this.openShareModal();
        break;
      }
      case PRIMER_ACTIONS.contact: {
        this.openContactDatasetModal();
        break;
      }
      // Vis submenu
      case PRIMER_ACTIONS_VIZ.createViz: {
        window.location.assign(localizeLink(this.props.view.bootstrapUrl ?? ''));
        break;
      }
      case PRIMER_ACTIONS_VIZ.carto: {
        this.openCartoModal();
        break;
      }
      case PRIMER_ACTIONS_VIZ.plotly: {
        this.openPlotlyModal();
        break;
      }
      case PRIMER_ACTIONS_VIZ.integrations: {
        window.open('https://support.socrata.com/hc/en-us/articles/115010730868', '_blank');
        break;
      }
    }
  };

  navQueryData = () => {
    const view = this.props.view;
    const isBlobbyOrHref = view.isBlobby || view.isHref;
    if (isBlobbyOrHref) {
      return null;
    }

    const url = shouldUseExploreInsteadOfGrid(view.coreView) ? view.exploreUrl : view.gridUrl;
    window.location.assign(localizeLink(url || ''));
  };

  watchDataset = () => {
    const { view, onSubscriptionChange } = this.props;
    const promise = view.subscribed
      ? subscriptionApi.unsubscribe(view.subscriptionId)
      : subscriptionApi.subscribe(view.id, WATCH_DATASET_ACTIVITY);

    promise
      .then((subscribedResult: any) => {
        if (view.subscribed) {
          showForgeSuccessToastNow(t('toast_message.dataset_stop_watching'));
        } else {
          showForgeSuccessToastNow(t('toast_message.dataset_watched'));
        }

        onSubscriptionChange(_.get(subscribedResult, 'id', null));
      })
      .catch(() => {
        showForgeErrorToastNow(t('toast_message.dataset_watching_failure'));
        onSubscriptionChange(null);
      });
  };

  muteDataset = () => {
    const { viewId, isMuted, muteId } = this.state;
    const promise = isMuted
      ? subscriptionApi.unsubscribe(muteId)
      : subscriptionApi.subscribe(viewId, MUTE_DATASET_ACTIVITY);

    promise
      .then((response: any) => {
        this.setState({
          muteId: get(response, 'id', null),
          isMuted: !isMuted
        });

        if (isMuted) {
          showForgeSuccessToastNow(t('toast_message.dataset_unmuted'));
        } else {
          showForgeSuccessToastNow(t('toast_message.dataset_muted'));
        }
      })
      .catch((error: any) => {
        showForgeErrorToastNow(t('toast_message.dataset_muting_failure'));
        console.error(error);
      });
  };

  openApiFlannel() {
    this.props.openExportModalToApi();
  }

  openShareModal() {
    this.setState({ shareModalOpen: true });
  }

  closeShareModal() {
    this.setState({ shareModalOpen: false });
  }

  openAlertModalOpen() {
    this.setState({ alertModalOpen: true });
  }

  closeAlertModal() {
    this.setState({ alertModalOpen: false });
  }

  openScheduleUpdates() {
    const { coreView } = this.props.view;
    if (coreView) {
      launchScheduleModal(coreView?.id, getScheduleParams(coreView));
    }
  }

  openODataModal() {
    this.openLegacyModal('#odata-modal');
  }

  openCartoModal() {
    this.openLegacyModal('#carto-modal');
  }

  openPlotlyModal() {
    this.openLegacyModal('#plotly-modal');
  }

  openContactDatasetModal() {
    this.openLegacyModal('#contact-form');
  }

  // todo need to test all the tab/focus stuff
  openLegacyModal(modalId: string) {
    const modal = document.querySelector(modalId) as HTMLElement;
    modal.classList.remove('modal-hidden');
    modal.setAttribute('data-legacy-modal', 'true');
    document.body.classList.add('modal-open');
    const windowWidth = window.innerWidth;
    const modalContainer = modal.querySelector<HTMLElement>('.modal-container');
    if (isMobile() && modalContainer) {
      modalContainer.style.left = `${windowWidth}px`;
    }
  }

  renderODataModal() {
    const ODataProps = { ...this.props, onClickCopy: _.noop };
    return <ODataModal {...ODataProps} />;
  }

  renderShareModal() {
    if (FeatureFlags.value('share_and_embed_tabular_data')) {
      return (
        <ShareAndEmbedComponent view={this.props.view} isModalOpen onCloseModal={this.closeShareModal} />
      );
    } else {
      const shareModalProps = { ...this.props, onDismiss: this.closeShareModal, onClickShareOption: _.noop };
      return <ShareModal {...shareModalProps} />;
    }
  }

  renderAlertModel() {
    return <CreateAlertModal onClose={this.closeAlertModal} />;
  }

  render() {
    const menuOptions = this.getActionBarMenu();

    const deviceSpecificProps = isMobile()
      ? {
          placement: 'right-start',
          dense: true
        }
      : {
          placement: 'left-start',
          dense: false
        };

    return (
      <div>
        <ForgeMenu
          options={menuOptions}
          className="action-button-menu"
          optionBuilder={this.optionBuilder}
          highlightFirst={false}
          on-forge-menu-select={(v: CustomEvent) => this.handleMenuSelect(v.detail.value)}
          popupClasses="action-button-menu-popup"
          {...deviceSpecificProps}
        >
          <ForgeButton type="raised" className="action-button" data-testid="action-menu-button">
            <button type="button" id="action-button" disabled={!menuOptions.length}>
              <span>{t('actions')}</span>
              <ForgeIcon name="keyboard_arrow_down" />
            </button>
          </ForgeButton>
          {!menuOptions.length && <ForgeTooltip position={'bottom'}>{t('no_actions')}</ForgeTooltip>}
        </ForgeMenu>
        {this.renderODataModal()}
        {this.state.shareModalOpen && this.renderShareModal()}
        {this.state.alertModalOpen && this.renderAlertModel()}
      </div>
    );
  }
}

interface StateProps {
  view: DatasetLandingPageEnhancedView;
}

const mapStateToProps = (state: any): StateProps => {
  return {
    view: state.view
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    onSubscriptionChange: (subscriptionId) => {
      dispatch(subscriptionChange(subscriptionId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionButton);
